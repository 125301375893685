import Vue from 'vue';
import App from './App';
import { router } from './router';
import 'normalize.css/normalize.css'

import { Popup, PullRefresh, List, Field, Cell, CellGroup, Form, Button, Uploader, ActionSheet, Picker, Image as VanImage, ImagePreview } from 'vant';

Vue.use(Popup)
Vue.use(PullRefresh)
Vue.use(List)
Vue.use(Field)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Form)
Vue.use(Button)
Vue.use(Uploader)
Vue.use(ActionSheet)
Vue.use(Picker)
Vue.use(VanImage)
Vue.use(ImagePreview)

import axios from 'axios'
Vue.prototype.$axios = axios

new Vue({
  router,
  el: '#app',
  render: h => h(App)
});
